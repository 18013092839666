import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2024/Class6/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2024/Class6/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2024/Class6/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2024/Class6/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2024/Class6/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2024/Class6/6.jpg';
// import p7 from 'assests/Photos/FirldTrips/2024/Class6/7.jpg';
// import p8 from 'assests/Photos/FirldTrips/2024/Class6/8.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const FlowerShow2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/visit-lalbagh/8.webp`;
  
  

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
       {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
     
      {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    },
 
  {
      src: p7,
      source: p7,
      rows: 2,
      cols: 1,
  },
  {
    src: p8,
    source: p8,
    rows: 2,
    cols: 1,
},

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                ‘ VISIT TO LAL BAGH FLOWER SHOW  ’
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6   Date: 13 August 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                A group of Class 6 students visited the Lalbagh Flower Show in Bengaluru on 13 August 2024, as part of their school's educational trip. They explored the floral displays, learned about plant species and were captivated by intricate arrangements honouring Dr B.R. Ambedkar's life. 
                                    <br />
                                    The show, held during the Independence Day and Republic Day weeks, featured vibrant flowers like roses, chrysanthemums, lilies and orchids, with a grand floral replica of an iconic structure as the centrepiece. The students were particularly impressed by the bonsai display and the educational sessions on plant preservation and biodiversity.
                                    <br />
                                    The visit provided both visual enjoyment and environmental education, with plans to make it an annual event to encourage students' appreciation of nature.
                                    <br />
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Always Looking Up”

                                <br />
                            </Typography> */}


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default FlowerShow2024;